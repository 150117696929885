<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách tổ chức</h6>
                  <router-link :to="'/charity/organization/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                    >Tạo mới
                    </b-button>
                  </router-link>
                </div>
              </template>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(image)="data">
                  <div>
                    <img width="50" :src="data.item.image" >
                  </div>
                </template>
                <template #cell(name)="data">
                  <div >
                    <div>
                      {{data.item.name}}
                    </div>
                  </div>

                </template>

                <template #cell(status)="data">
                  <div class="label label-inline label-light-success" v-if="data.item.status == 1">
                    Active
                  </div>
                  <div class="label label-inline label-light-danger" v-else>
                    InActive
                  </div>
                </template>

                <template #cell(type)="data">
                  <div >
                    {{data.item.products.length}}
                  </div>

                </template>


                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                        :to="'/charity/organization/edit/' + data.item.id"
                    >
                      <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa </b-button>
                    </router-link>

                    <b-button
                        @click="destroy(data.item.id)"
                        pill
                        variant="danger"
                        size="sm"
                    >Xóa
                    </b-button>
                  </div>
                </template>
              </b-table>
<!--              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>-->
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";

const BackendRepository = RepositoryFactory.get("Backend");

export default {
  name: "List.vue",
  mixins: [Common],
  components:{
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "id",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "image",
          label: "Icon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Số camp",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Ngày tạo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý tổ chức", route: "/charity/organization" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    getType(items){
      let array_type=[];
      let that = this;
      let label = '';
      items.forEach((item) => {
        if (!array_type.includes(item.type)){
          array_type.push(item.type)
        }
      })
      array_type.forEach((item)=>{
        that.arrayFilter.forEach((type)=>{
          if (item == type.status){
            label += type.label+ ', ';
          }
        })
      })
      return label.replace(/, +$/, "");
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        let param ={
          page:this.currentPage
        }
        //this.paramFilter.page = this.currentPage;

        let response = await BackendRepository.listOrganization(param);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa tổ chức")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await BackendRepository.deleteOrganization(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },

  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
